// Footer.js
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterContainer = styled.div`
  padding: 20px;
  text-align: center;
  background-color: #111;
  color: #f5f5f5;  /* Light text color for dark background */
`;

const FooterText = styled.p`
  font-size: 1rem;
  margin: 10px 0;
`;

const FooterLinks = styled.div`
  margin-top: 10px;
`;

const FooterLink = styled(Link)`
  color: #FFFFFF;  /* Accent color for links */
  margin: 0 10px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterText>&copy; 2024 HookedUp Co. All rights reserved.</FooterText>
      <FooterText>Contact Us: info@metrixaiapp.com </FooterText>
      <FooterLinks>
        <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
        <FooterLink to="/terms-of-service">Terms of Service</FooterLink>
      </FooterLinks>
    </FooterContainer>
  );
};

export default Footer;
