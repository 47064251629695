// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GlobalStyle from './GlobalStyle';
import HeroSection from './HeroSection';
import FeaturesSection from './FeaturesSection';
import Footer from './Footer';
import PrivacyPolicy from './PrivacyPolicy'; // Create this component
import TermsOfService from './TermsOfService'; // Create this component

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>
    </Router>
  );
}

// Home component to render HeroSection, FeaturesSection, and Footer
function Home() {
  return (
    <>
      <HeroSection />
      <FeaturesSection />
      <Footer />
    </>
  );
}

export default App;
