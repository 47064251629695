// src/FeaturesSection.js
import React from 'react';
import styled from 'styled-components';
import { FaRobot, FaImage, FaComments } from 'react-icons/fa';

const FeaturesContainer = styled.div`
  padding: 60px 30px;
  text-align: center;
  background: #121212;  /* Dark background */
  color: #f5f5f5;  /* Light text color */
`;

const FeaturesTitle = styled.h2`
  font-size: 3rem;
  margin-bottom: 50px;
  color: #ffffff;  /* White color for title */
`;

const FeaturesGrid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
`;

const Feature = styled.div`
  flex: 1;
  margin: 10px;
  padding: 30px;
  background-color: #1e1e1e;  /* Slightly lighter dark color for feature background */
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);  /* Darker shadow for dark theme */
  max-width: 300px;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.7);  /* More pronounced shadow on hover */
  }
`;

const IconWrapper = styled.div`
  font-size: 2rem;
  margin-bottom: 20px;
  color: #b04040;  /* Accent color for icons */
`;

const FeatureTitle = styled.h3`
  font-size: 1.75rem;
  margin-bottom: 15px;
  color: #ffffff;  /* White color for feature titles */
`;

const FeatureDescription = styled.p`
  font-size: 1.125rem;
  color: #e0e0e0;  /* Light grey color for description text */
`;

const FeaturesSection = () => {
  return (
    <FeaturesContainer>
      <FeaturesTitle>Features</FeaturesTitle>
      <FeaturesGrid>
        <Feature>
          <IconWrapper>
            <FaRobot />
          </IconWrapper>
          <FeatureTitle>Picture Based Calorie Tracking</FeatureTitle>
          <FeatureDescription>Use our AI and calculate your calories effectively.</FeatureDescription>
        </Feature>
        <Feature>
          <IconWrapper>
            <FaImage />
          </IconWrapper>
          <FeatureTitle>Text Based Calorie Tracking</FeatureTitle>
          <FeatureDescription>Simply describe what your eating and we'll calculate the macros.</FeatureDescription>
        </Feature>
        <Feature>
          <IconWrapper>
            <FaComments />
          </IconWrapper>
          <FeatureTitle>Keep Track of your Daily Macros</FeatureTitle>
          <FeatureDescription>Our simple UI makes this simple yet effective.</FeatureDescription>
        </Feature>
      </FeaturesGrid>
    </FeaturesContainer>
  );
};

export default FeaturesSection;
